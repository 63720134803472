import Image from "next/image";
import Section from "./common/section";
import SectionTitle from "./common/section-title";

const data = [
  {
    date: "2022-07-05",
    image:
      "https://kubrick.htvapps.com/htv-prod-media.s3.amazonaws.com/images/poster-image-2022-07-25t150219-830-1658776027.jpg?crop=1.00xw:1.00xh;0,0&resize=640:*",
    link: "https://www.wtae.com/article/a-life-changing-addition-for-one-pennsylvania-family/40709753",
    title: "A Life Changing Addition for One Pennsylvania Family",
    content:
      "The Glock Foundaton is honored to have contributed monies to Canine Service Pals along with the Young Women’s Breast Cancer Awareness Foundation to bring home Oreo a trained service dog exclusively for Cara Snyder’s family.",
  },
  {
    date: "2019-10-08",
    iframe:
      "https://www.iheart.com/podcast/1269-i-had-cancer-42667537/episode/episode-23-investigational-drug-eliminates-breast-50427483/?embed=true",
    title:
      "I Had Cancer Podcast - Investigational Drug Eliminates Breast Cancer",
    content:
      "Carol discovered a walnut-size lump in her breast and was diagnosed with inflammatory breast cancer, which is rare and aggressive and carries a 20 percent chance of survival. Carol talks about how a clinical trial at AHN helped her beat the odds.",
  },
  {
    date: "2017-11-06",
    video: "/press/livingproofcarol.mp4",
    poster: "/press/carol-livingproof.png",
    title: "Living Proof: Allegheny Health Network Together with Johns Hopkins",
    content:
      "Carol Glock is featured in an Allegheny Health Network and Johns Hopkins living proof commerical.",
  },
];

export default function Press() {
  return (
    <Section id="press" bg="bg-neutral px-4">
      <SectionTitle>Press</SectionTitle>
      <div className="grid md:grid-cols-2 container mx-auto bg-white mt-8">
        <div className="p-4">
          <div className="relative min-h-[300px] 2xl:min-h-[400px]">
            <Image
              alt="Life-Changeing"
              src={data[0].image || ""}
              layout="fill"
              objectFit="cover"
              quality={100}
            />
          </div>
        </div>
        <div className="p-4 min-h-[300px] 2xl:min-h-[400px] prose">
          <a
            href={data[0].link}
            target="_blank"
            rel="noopener noreferrer"
            className="no-underline hover:underline"
          >
            <h2>{data[0].title}</h2>
          </a>
          <div>
            <p>{data[0].content}</p>
          </div>
        </div>

        <div className="p-4 md:hidden md:min-h-[300px] 2xl:min-h-[400px]">
          <iframe
            allow="autoplay"
            width="100%"
            height="300"
            src="https://www.iheart.com/podcast/1269-i-had-cancer-42667537/episode/episode-23-investigational-drug-eliminates-breast-50427483/?embed=true"
            frameBorder="0"
          ></iframe>
        </div>
        <div className="p-4 min-h-[300px] 2xl:min-h-[400px] prose">
          <h2>
            I Had Cancer Podcast - Investigational Drug Eliminates Breast Cancer
          </h2>
          <div>
            <p>
              Carol discovered a walnut-size lump in her breast and was
              diagnosed with inflammatory breast cancer, which is rare and
              aggressive and carries a 20 percent chance of survival. Carol
              talks about how a clinical trial at AHN helped her beat the odds.
            </p>
          </div>
        </div>
        <div className="hidden md:block p-4 min-h-[300px] 2xl:min-h-[400px]">
          <iframe
            allow="autoplay"
            width="100%"
            height="300"
            src="https://www.iheart.com/podcast/1269-i-had-cancer-42667537/episode/episode-23-investigational-drug-eliminates-breast-50427483/?embed=true"
            frameBorder="0"
          ></iframe>
        </div>

        {/* Press card 2 */}
        <div className="p-4 min-h-[300px] 2xl:min-h-[400px]">
          <video
            controls
            controlsList="nodownload"
            width="100%"
            height="auto"
            src="/press/livingproofcarol.mp4"
            poster="/press/carol-livingproof.png"
          ></video>
        </div>
        <div className="p-4 min-h-[300px] 2xl:min-h-[400px] prose">
          <h2>
            Living Proof: Allegheny Health Network Together with Johns Hopkins
          </h2>
          <div>
            <p>
              Carol Glock is featured in an Allegheny Health Network and Johns
              Hopkins living proof commerical.
            </p>
          </div>
        </div>

        {/* <div className="p-4 min-h-[300px] 2xl:min-h-[400px]">
          <div className="h-full w-full relative">
            <Image
              alt="Pittsburgh Today"
              src="/pittsburghtoday.png"
              layout="fill"
              objectFit="cover"
              quality={100}
            />
          </div>
        </div>
        <div className="p-4 min-h-[300px] 2xl:min-h-[400px] prose">
          <h2>
            Glock Foundation Raising Awareness About Breast Cancer Clinical
            Trials
          </h2>
          <div>
            <p>
              Carol Glock and Dr. Thomas Julian stop by to talk about how the
              Glock Foundation is working to raise awareness about breast cancer
              clinical trials and much more.
            </p>
          </div>
        </div> */}
      </div>
    </Section>
  );
}

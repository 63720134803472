import Link from "next/link";
import { useState } from "react";

export default function NotificationBanner() {
  const [show, setShow] = useState(true);

  const handleDismiss = () => {
    setShow(false);
  };

  if (!show) return null;

  return (
    <div id="alert-border-1" className="banner-alert" role="alert">
      <svg
        className="flex-shrink-0 w-5 h-5 banner-alert-text"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clipRule="evenodd"
        ></path>
      </svg>
      <div className="flex flex-col ml-3 text-sm font-medium banner-alert-text w-full text-center justify-center items-center md:flex md:flex-row">
        <span>
          Register Now for{" "}
          <span className="font-bold">{`Far Away Fairways 2024`}</span>
        </span>
        <div className="mt-4 md:mt-0">
          <Link href="/farawayfairways">
            <a className="ml-4 rounded-full border banner-alert-border btn-sm py-1 hover:banner-alert-hover">
              Register
            </a>
          </Link>
        </div>
        {/* A simple info alert with an{" "} */}
        {/* <a href="#" className="font-semibold underline hover:text-blue-800">
          example link
        </a> */}
        {/* . Give it a click if you like. */}
      </div>
      <button
        type="button"
        className="-mx-1.5 -my-1.5 banner-alert-bg banner-alert-text rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:banner-alert-hover inline-flex h-8 w-8"
        data-dismiss-target="#alert-border-1"
        aria-label="Close"
        onClick={handleDismiss}
      >
        <span className="sr-only">Dismiss</span>
        <svg
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
    </div>
  );
}

export default function Conversation() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      width="842"
      height="842.79999"
      viewBox="0 0 842 842.79999"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M1021,449.6a420.09825,420.09825,0,0,1-24.1,140.73c-.07.21-.14.4-.22.61v.01a419.10164,419.10164,0,0,1-48.3,95.08q-5.22,7.71-10.81,15.17-6.15006,8.25-12.69,16.17-6.03,7.30509-12.38,14.33-7.545,8.37-15.54,16.31a421.16963,421.16963,0,0,1-39.88,35q-3.69,2.865-7.46,5.63-8.775,6.48-17.9,12.5A417.4181,417.4181,0,0,1,790.15,825.3c-2.47,1.26-4.96,2.5-7.46,3.69995q-14.505,7.00506-29.6,12.89c-2.87006,1.13-5.75,2.22-8.66,3.27-.49.19-.98.37-1.48.54a418.30758,418.30758,0,0,1-107.06,23.38q-3.81.345-7.63.58-4.05.285-8.11.46c-2.14.11005-4.27.19-6.42.25-.44.02-.88995.04-1.33.04q-4.335.135-8.7.17c-1.23.01-2.46.02-3.7.02-.58,0-1.16,0-1.74-.01q-12.585-.045-24.98-.82c-.17-.01-.34-.03-.51-.03-2.96-.2-5.91-.41-8.85-.67q-15.705-1.32-31.07-3.8-3.54-.555-7.06-1.2-15.285-2.7-30.17-6.51-4.425-1.125-8.8-2.35a418.62716,418.62716,0,0,1-114.54-51.46q-9.12-5.865-17.9-12.19995c-.72-.5-1.43-1.02-2.14-1.54a422.1355,422.1355,0,0,1-40.59-33.67q-9.105-8.55-17.68-17.64A419.38853,419.38853,0,0,1,179,449.6c0-232.51,188.49-421,421-421S1021,217.09,1021,449.6Z"
        transform="translate(-179 -28.60001)"
        fill="transparent"
        // fill="#f2f2f2"
      />
      <path
        d="M651.36038,135.92775c8.42937-13.94147,18.41783-28.82681,34.06057-33.37912,18.11539-5.27183,36.72442,5.20086,52.50916,15.53525a1530.11613,1530.11613,0,0,1,136.1711,100.32918l-.0476.54118q-50.80647-3.50372-101.61282-7.00736c-24.47179-1.68762-49.78432-3.608-71.41061-15.18471-8.20644-4.39291-16.20594-10.261-25.5052-10.66986-11.55345-.508-21.62733,7.561-29.544,15.99113-46.82965,49.86722-60.38123,126.12612-112.91942,169.93821A1667.327,1667.327,0,0,1,651.36038,135.92775Z"
        transform="translate(-179 -28.60001)"
        fill="#fff"
      />
      <path
        d="M363.50531,558.89228c5.1786-6.53918,7.29461-8.07066,12.40286-14.67035q62.38968-80.69187,117.28256-166.83485Q530.47607,318.882,564.06366,258.13q15.99039-28.88222,31.10408-58.21787Q606.89559,177.16608,618.1,154.14793c2.13619-4.383,4.21186-8.78611,6.2772-13.19931,4.86668-10.37805,9.64284-20.80647,14.77126-31.0435,5.84376-11.66772,12.95733-23.89974,23.68843-31.73873a32.319,32.319,0,0,1,16.83661-6.47873c8.73563-.56424,16.79626,3.04291,24.37327,6.98251,55.437,28.90744,107.33715,65.15008,154.28008,106.39027A803.19758,803.19758,0,0,1,983.67,324.197c1.10794,1.58191,3.72774.07054,2.60947-1.52139q-6.60486-9.34033-13.47145-18.479A805.45868,805.45868,0,0,0,842.27643,167.41773a810.70028,810.70028,0,0,0-75.65893-55.54782Q746.56088,98.87217,725.75,87.09352c-6.99253-3.95978-14.01557-7.86919-21.15915-11.53673-7.00287-3.59706-14.43868-6.83141-22.4388-6.95232-27.2248-.42316-41.32106,30.40872-51.00375,51.1548q-4.56436,9.79364-9.23942,19.52688Q604.24111,176.239,585.213,212.517q-11.74337,22.39849-24.00084,44.535-38.14656,68.93347-81.08992,135.076c-37.92541,58.40931-75.54824,110.00744-118.7531,164.62824C360.18,558.26758,362.30585,560.42382,363.50531,558.89228Z"
        transform="translate(-179 -28.60001)"
        fill="#fff"
      />
      <path
        d="M514.18529,313.71566,473.62346,298.88l-20.13909-7.366c-6.559-2.399-13.10216-5.13-19.88257-6.84779-5.99476-1.51874-12.086-1.76727-17.99616.22929a41.47727,41.47727,0,0,0-14.03656,8.7012,113.91033,113.91033,0,0,0-11.51293,12.295c-4.38465,5.26937-8.70454,10.594-13.0438,15.90073q-26.86345,32.8531-53.37562,65.99138-26.51155,33.13773-52.66861,66.557-26.221,33.501-52.08133,67.28215-3.16794,4.13832-6.33045,8.28078c-1.16482,1.52565-3.79132.02156-2.61-1.52563q26.05111-34.12086,52.46539-67.9624,26.47924-33.92241,53.3244-67.55706,26.84465-33.634,54.05208-66.97628,6.77819-8.30657,13.57876-16.59484c3.82435-4.661,7.60558-9.37221,11.80024-13.71045,7.69961-7.96315,17.31929-15.00044,28.83417-15.19137,6.77014-.11227,13.32219,2.036,19.61236,4.31162,6.78109,2.45316,13.5459,4.95222,20.31831,7.42926L504.77749,307.066l10.21136,3.73486c1.81214.6628,1.02932,3.58514-.80356,2.91476Z"
        transform="translate(-179 -28.60001)"
        fill="#fff"
      />
      <path
        d="M403.09639,313.86386c3.9928-4.86028,8.30969-9.94928,14.28192-11.92342l5.57582.21991A632.46125,632.46125,0,0,1,254.4982,494.7451Q328.7973,404.30452,403.09639,313.86386Z"
        transform="translate(-179 -28.60001)"
        fill="#fff"
      />
      <path
        d="M674.24253,236.2123A46.81961,46.81961,0,1,1,593.7128,203.738q.11247-.11212.22472-.2245c.37458-.39344.74916-.78665,1.14249-1.16134a.01833.01833,0,0,0,.01863-.01875c.48694-.44945.97389-.8989,1.47957-1.3296a46.8073,46.8073,0,0,1,77.66432,35.20844Z"
        transform="translate(-179 -28.60001)"
        fill="#2f2e41"
      />
      <circle
        cx="615.88796"
        cy="247.04501"
        r="39.26025"
        transform="translate(-173.29779 479.25635) rotate(-45)"
        fill="#9e616a"
      />
      <path
        d="M643.94,209.29a44.27175,44.27175,0,0,0-46.23-8.96q-.34506.135-.69.3c-.01,0-.01.01-.02.01-.75.31-1.51.63-2.24.98l-.45.22v.06c-.08.06-.17.13-.26.19a1.53474,1.53474,0,0,1-.65.36c-7.55.1-13.7,2.66-18.26,7.61-6.09,6.6-8.95,17.27-8.07,30.05005l.02.38995.34.22c3.22,2.01,6.27,3.78,9.21,5.33,1.74.93,3.43,1.78,5.11,2.56.2.09.4.19.6.28,17,7.85,32.55,9.27,47.49,4.3l.28-.09,6.35-10.64a18.1362,18.1362,0,0,0,14.6,14.63l2.64.47,3.87.68.16-.73C661.58,240.21,656.03,220.84,643.94,209.29Z"
        transform="translate(-179 -28.60001)"
        fill="#2f2e41"
      />
      <path
        d="M740.387,291.69125c-13.36017-6.89584,3.82248-31.92348.36977-46.55644-3.45284-14.633-28.31671-19.33942-29.90065-34.2906S705.54594,180.4725,695.68,169.12743c-6.30431-7.24949-15.5937-12.60128-25.15789-11.69324a22.62053,22.62053,0,0,0-19.8254,27.93219c-2.72311-3.8585-9.77028-3.02224-11.51459,1.36664-1.55089,3.90171,1.87508,8.6278,5.90687,8.973,5.27566,6.51519,11.31694,13.42955,19.582,14.74383,2.66252.42339,5.65515.11316,7.59961-1.75413s2.09568-5.60052-.2109-6.996c10.38685,4.126,16.47648,15.35628,18.01767,26.42588,1.54107,11.06959-.59108,22.26972-1.99612,33.35737s-2.01041,22.7514,2.337,33.0476c5.13644,12.1651,16.96227,20.8841,29.86807,23.68025s26.65026.06333,38.33218-6.09364C770.30045,305.9598,780.042,296.56021,788,286.02214,774.29189,295.756,755.32663,299.40254,740.387,291.69125Z"
        transform="translate(-179 -28.60001)"
        fill="#2f2e41"
      />
      <polygon
        points="409.593 428.003 382.475 613.181 530.504 602.024 486.197 419.853 409.593 428.003"
        fill="#ffb8b8"
      />
      <path
        d="M743.45,843.81a17.82363,17.82363,0,0,1-.5,1.89,414.80083,414.80083,0,0,1-52.3,15.11c-3.74-12.3-10.91-35.59-17.19-54.64-5.13-15.55005-9.66-28.27-11.24-29.86-4.38995-4.37,0-10.2,0-10.2l-18.75-58.29-8.52-26.48-2.92,55.63-4.24,80.58-.12,2.26s-5.84,17.49-2.92,20.41-2.92,14.59-2.92,14.59l-1.68,15.31c-2.14.11005-4.27.19-6.42.25L613,870.6l-.6-.19q-4.335.135-8.7.17c-1.23.01-2.46.02-3.7.02-.58,0-1.16,0-1.74-.01q-12.585-.045-24.98-.82l-5.13-41.75-5.3-43.14s5.83-11.65,0-17.48c-3.51-3.51-1.75-8.61.22-12.11-1.22-5.41-2.89-13.73-5.14-26.05-.08-.41-.15-.83-.23-1.25q-1.065-5.865-1.91-11.5c-1-6.61005-1.78-12.96-2.38-19.04a334.22632,334.22632,0,0,1-.84-59.27c.56-7.02,1.26-12.16,1.72-15.12994.04-.28.47-1.85,1.17-4.33,1.1-3.97,2.91-10.27,4.94-17.32,1.08-3.74,2.22-7.69,3.35-11.62,1.97-6.81,3.93-13.56,5.51-19.02v-.03c1.39-4.79,2.5-8.57,3.08-10.55.28-.97.43-1.5.43-1.5s.31-.06994.9-.2c1.83-.42,6.35-1.43,12.61005-2.78,6.53-1.38,14.93994-3.13,24.18-4.91,7.91-1.52,16.43-3.07,24.9-4.46,3.04-.49,6.08-.97,9.08-1.42,18.53-2.76,35.54-4.33,43.6-2.54a15.01633,15.01633,0,0,1,1.65.45,8.20694,8.20694,0,0,1,1.57.71c.25.15,2.67,9.59,5.76,21.89.03.12.06.25.09.37.93,3.72,1.92,7.68,2.93,11.73,1.03,4.13,2.07,8.34,3.09,12.44,3.03,12.18,5.83,23.45,7.27,28.97.55,2.14.91,3.41.99,3.55,3.38,5.63,6.14,19.43,8.38,36.74,2,15.45,3.59,33.7,4.82,51.43.61,8.77,1.12994,17.42,1.56994,25.54,1.49,27.32,2.09,48.65,2.09,48.65s2.23,17.78,6.66,26.17a12.75667,12.75667,0,0,0,2.07,2.97c2.95,2.95,3.29,5.51,2.9,7.33a6.05741,6.05741,0,0,1-1.04,2.38,3.94265,3.94265,0,0,1-.39.49,11.34938,11.34938,0,0,0,2.92,10.2C744.2,837.23,743.97,841.31,743.45,843.81Z"
        transform="translate(-179 -28.60001)"
        fill="#2f2e41"
      />
      <path
        d="M694.96,397.21q-.25506-1.89-.54-3.81c-.11-.82-.24-1.64-.36-2.45-1.27-8.18,4.02-15.18-2.83-20.84a14.54,14.54,0,0,1-3.83-4.78l-1.57-3.16-2.89-5.86-6.32-12.74-1.09-2.19a14.04033,14.04033,0,0,0-1.06006-1.79,14.4469,14.4469,0,0,0-4.51-4.22l-15.7-9.36a14.48761,14.48761,0,0,1-5.62-6.09l-.39-.8c-1.73-3.55-4.83-12.04-8.5-15.73l-.01-.01a5.37977,5.37977,0,0,0-3.08-1.75L621.03,300.08a14.49353,14.49353,0,0,0-13.11,5.79l-.43,1.95-2.51,11.43-.13995.03c-.74.17-1.48.34-2.21.55-16.21,4.43-22.97,61.1-22.97,61.1s-11.35,18.73-6.88995,30.32l7.96,18.69a2.25279,2.25279,0,0,1,.14.44,14.38965,14.38965,0,0,1,.74,6.35l.01.73.02,1.61.06,4.13.19,13.7.24,17.15.21,14.61.06994,3.89L569.3,549.6a14.35058,14.35058,0,0,0,3.03,10.57l.01.01a13.42949,13.42949,0,0,0,.92,1.06006l.01.01c.31.32.63.61005.96.9a14.38245,14.38245,0,0,0,11.66,3.39l1.75-.27h.01l25.4-3.81h.02l2.94-.44h.02l28.43-4.27h.02l4.87-.74c.39-.05.77-.1,1.16-.13l26.03-1.79a14.66809,14.66809,0,0,0,1.78-.23h.01a14.5395,14.5395,0,0,0,10.47-8.37,12.885,12.885,0,0,0,.89-2.67,15.044,15.044,0,0,0,.37-3.03l.29-24.52.99-79.24a14.26361,14.26361,0,0,1,2.18-7.47C697.13,422.8,696.72,410.46,694.96,397.21Z"
        transform="translate(-179 -28.60001)"
        fill="#636198"
      />
      <path
        d="M651.31668,608.84346a14.0486,14.0486,0,0,1,13.78595-16.55285l16.23968-47.20875,15.83408,20.55053-17.849,41.67962a14.1247,14.1247,0,0,1-28.0107,1.53145Z"
        transform="translate(-179 -28.60001)"
        fill="#9e616a"
      />
      <path
        d="M713.28,509.52l-3.65,9.2-5.1,56.91a16.0484,16.0484,0,0,0-4.49,1.89,15.75876,15.75876,0,0,0-6.65,18.42l1.5,4.5-11.15-3.43-18.33-5.63L643.8,584.74,647.46,572.87a17.61669,17.61669,0,0,0-2.98-16.12,17.43871,17.43871,0,0,0-1.41-1.59l1.37-10.25.81-6.09-9.89,7.51-19.34,14.69h-.01l-2.56,1.95s-.14-.52-.4-1.51c-.49-1.91-1.41-5.57-2.59-10.67a648.08881,648.08881,0,0,1-12.89-77.09c-.6-5.84-1.12-11.82-1.54-17.89-.43005-6.25-.75-12.59-.92-18.97-1.44-51.93,6.54-106.37,39.89-134.21a15.91148,15.91148,0,0,1,4.75.76c13.5,4.15,28.31,24.43,38.55,38.94,2.42,3.43,4.58,6.53,6.41,9.02l4.43,6.03a70.56615,70.56615,0,0,1,12.27,27.71q.64509,3.18,1.01,6.44l4.84,44.52v11.47Z"
        transform="translate(-179 -28.60001)"
        fill="#636198"
      />
      <path
        d="M203.38973,589.32739a289.00517,289.00517,0,0,0,28.12,28.95l737.58,1a289.00407,289.00407,0,0,0,28.12-28.95Z"
        transform="translate(-179 -28.60001)"
        fill="#ccc"
      />
      <path
        d="M603.7,870.58c-1.23.01-2.46.02-3.7.02-.58,0-1.16,0-1.74-.01q-12.585-.045-24.98-.82l.19995,1.63-.71-1.66-15.85-36.9,11.23-4.82,20.6-8.85L595,840.66l5.98,20.58,1.78,6.1Z"
        transform="translate(-179 -28.60001)"
        fill="#ffb6b6"
      />
      <path
        d="M530.27,827.74l-4.48,36.33q-15.285-2.7-30.17-6.51V827.75Z"
        transform="translate(-179 -28.60001)"
        fill="#ffb6b6"
      />
      <path
        d="M372.28,760.78v42.97q-9.12-5.865-17.9-12.19995V760.78Z"
        transform="translate(-179 -28.60001)"
        fill="#cacaca"
      />
      <path
        d="M441.63,753.62v21.47a14.92439,14.92439,0,0,1-14.91,14.92H352.24a422.1355,422.1355,0,0,1-40.59-33.67q-9.105-8.55-17.68-17.64H426.72a14.92993,14.92993,0,0,1,14.91,14.92Z"
        transform="translate(-179 -28.60001)"
        fill="#3f3d56"
      />
      <path
        d="M616.18,869.59l-2.45.78L613,870.6l-.6-.19q-4.335.135-8.7.17c-1.23.01-2.46.02-3.7.02-.58,0-1.16,0-1.74-.01q-12.585-.045-24.98-.82l.19995,1.63-.71-1.66c-2.96-.2-5.91-.41-8.85-.67L540.65,830.1l-47.89-80.19-5.55-9.29s-18.93,5.6-45.72,10.97c-20.19,4.06-44.84,7.97-69.21,9.25-6.01.32-12,.48-17.9.43-15.25-.1-29.9-1.53-42.73-4.93q-9.105-8.55-17.68-17.64a421.2244,421.2244,0,0,1-27.62-32.33,78.01548,78.01548,0,0,1,.63-12.34c.09-.68.18-1.37.29-2.07L270,645.6l20.28-2.68,30.86-4.08,33.43-4.42,3.68,28.82s26.03-1.32,59.01-.88c52.97.72,123.85,6,133.45,28.66.9,2.13,1.8,4.28,2.7,6.43,18.64,44.65,35.76,92.22,47.39,126.02,2.22,6.44,4.23,12.37,6.02,17.69C612.73,858.73,616.18,869.59,616.18,869.59Z"
        transform="translate(-179 -28.60001)"
        fill="#2f2e41"
      />
      <path
        d="M559.48,716.57q-.735,6.36-1.55,12.67c-4.8,37.21-11.41,72.75-17.28,100.86-2.87,13.72-5.57,25.66-7.8,35.17q-3.54-.555-7.06-1.2-15.285-2.7-30.17-6.51-4.425-1.125-8.8-2.35l5.94-105.3,1.96-34.83,61.07,1.41Z"
        transform="translate(-179 -28.60001)"
        fill="#2f2e41"
      />
      <path
        d="M657.67476,553.69906A19.95195,19.95195,0,0,1,686.26088,564.6l70.90263.08387-20.32024,30.73474-64.21506-4.79152a20.06012,20.06012,0,0,1-14.95341-36.92791Z"
        transform="translate(-179 -28.60001)"
        fill="#ffb6b6"
      />
      <path
        d="M696.33087,595.26373,824.028,607.94965l.33662-.43078c.64426-.82345,64.56565-82.64615,75.3054-102.12392a58.89053,58.89053,0,0,0,6.81965-38.57106l-.07326-.32417-.25427-.215a29.885,29.885,0,0,0-37.36866-.8194,211.68276,211.68276,0,0,0-61.088,75.48336l-8.07961,16.821-93.71046,4.02041Z"
        transform="translate(-179 -28.60001)"
        fill="#636198"
      />
      <path
        d="M647.08,825.84l-18.82,43.82q-4.05.285-8.11.46c-2.14.11005-4.27.19-6.42.25L613,870.6l-.6-.19q-4.335.135-8.7.17c-1.23.01-2.46.02-3.7.02-.58,0-1.16,0-1.74-.01l1.25-4.28,1.47-5.07,5.84-20.08,8.43-28.99,12.54,5.38Z"
        transform="translate(-179 -28.60001)"
        fill="#ffb6b6"
      />
      <path
        d="M782.69,829q-14.505,7.00506-29.6,12.89l-9.84-16.55,31.72-13.93Z"
        transform="translate(-179 -28.60001)"
        fill="#ffb6b6"
      />
      <path
        d="M849.62,753.78v34.86q-8.775,6.48-17.9,12.5V753.78Z"
        transform="translate(-179 -28.60001)"
        fill="#cacaca"
      />
      <path
        d="M912.5,731.7q-7.545,8.37-15.54,16.31a421.16963,421.16963,0,0,1-39.88,35h-79.8a14.8793,14.8793,0,0,1-11.19-5.07l-.01-.03a14.72677,14.72677,0,0,1-3.36005-6.6l-.01-.02a15.30179,15.30179,0,0,1-.34-3.2V746.62a14.92993,14.92993,0,0,1,14.91-14.92Z"
        transform="translate(-179 -28.60001)"
        fill="#3f3d56"
      />
      <path
        d="M945.38,647.9l-8.65,37.06a77.90187,77.90187,0,0,1,.84,16.24q-6.15006,8.25-12.69,16.17-6.03,7.30509-12.38,14.33-7.545,8.37-15.54,16.31c-13.88,4.36-30.24,6.14-47.34,6.26-5.9.05-11.89-.11-17.9-.43-24.37-1.28-49.02-5.19-69.21-9.25-5.34-1.06-10.36-2.14-14.98-3.19-8.75-1.97-16.07-3.81-21.37006-5.21-5.97-1.56-9.37-2.57-9.37-2.57l-22.71,38.03-20.62,34.52-37.57,62.91q-3.81.345-7.63.58-4.05.285-8.11.46c-2.14.11005-4.27.19-6.42.25L613,870.6l-.6-.19-9.64-3.07-3.25-1.03-11.69-3.72s2.61-8.22,7.18-21.93c1.68-5.03,3.62-10.81,5.8-17.19,7.87-23.06,18.78-54.03,31.23-86.5,3.68994-9.64,7.51-19.42,11.43994-29.15,3.22-7.99,6.5-15.95,9.82-23.8,5.77-13.61,33.65-20.95,66.48-24.8,1.67-.19,3.35-.38,5.04-.56,16.73-1.76,34.48-2.67,51.08-3.09,8.31-.21,16.33-.29,23.79-.3q3.61506,0,7.03.02h.01c6.15.03,11.81006.12,16.79.22.97.02,1.91.05,2.83.07h.01c.62.01,1.23.03,1.83.04,10.89.28,17.57.62006,17.57.62006l2.59-20.32.53-4.1.5-3.9v-.01l.06-.49.41-.01,7.2-.14,60.52-1.18,33.06-.65Z"
        transform="translate(-179 -28.60001)"
        fill="#2f2e41"
      />
      <path
        d="M790.15,825.3c-2.47,1.26-4.96,2.5-7.46,3.69995q-14.505,7.00506-29.6,12.89c-2.87006,1.13-5.75,2.22-8.66,3.27-.32-.45-.65-.9-.98-1.35-13.88-19.05-31.52-44.19-49.37-72.16-7.47-11.7-14.98-23.9-22.27-36.36l52.78-24.64,5.92-2.76,17.02,33.51,15.18,29.89,3.38,6.65Z"
        transform="translate(-179 -28.60001)"
        fill="#2f2e41"
      />
      <circle cx="687.76201" cy="331.42587" r="48.62546" fill="#ffb6b6" />
      <path
        d="M865.15332,406.5402c11.36857,5.45484,25.36173,3.60723,36.21585-2.81055s18.84178-16.88437,24.42383-28.191c4.08816-8.28075,7.05837-17.76388,4.69072-26.69014s-11.81165-16.40554-20.66-13.76161c6.062-4.8812,5.35532-14.94731.45692-20.9954s-12.62677-8.95632-20.09465-11.14826c-10.838-3.18112-22.22619-5.42646-33.40356-3.79932s-22.18215,7.67862-27.44173,17.67452a17.03867,17.03867,0,0,1-19.14363-8.22143,15.43672,15.43672,0,0,0,5.30681,18.00935l-15.138-8.21306c-3.8589,5.34923-.35238,13.50758,5.5062,16.53782s12.9401,2.22195,19.33415.60287-1.14981,11.88417,5.44476,12.01418,13.72836,3.58128,15.61642,9.90113c.98891,3.31012.41251,6.86475.70435,10.30708s1.94108,7.26134,5.27735,8.1581c2.10784.56657,4.41391-.18405,6.50394.445,2.59111.77993,4.17057,3.55151,4.39855,6.24784s-.61753,5.355-1.45246,7.92888c-3.29953,10.17169,7.28988,4.47031,3.99036,14.642Z"
        transform="translate(-179 -28.60001)"
        fill="#2f2e41"
      />
      <path
        d="M960.56,550.17c-.4-40.16-2.22-67.53-5.4-81.36-8.54-37.18-47.99-43.96-54.17-44.8a13.64477,13.64477,0,0,0-18.45-7.61l-28.03,12.84a45.66438,45.66438,0,0,0-24.27,27.1l-4.16,12.7-2.09,85.23,6.9,35.85.01.02,4.65,24.15-3.2,4.8-18.27,27.4-.31.48-.01.01-.8,1.19c1.11.61,2.21,1.21,3.29,1.81q4.2,2.31,8.19,4.53c.64.36,1.27.71,1.9,1.07h.01c.4.22.8.44,1.19.66Q842.51,664.61,854.79,671.75c1.14.66,2.27,1.32,3.38,1.97,37.8,22.11,56.96,35.65,66.71,43.65q6.54-7.91994,12.69-16.17,5.58-7.455,10.81-15.17c.61-4.02,1.02-8.04,1.42-11.98.48-4.74.98-9.65,1.82-14.48.37-2.14-1.05-4.17-2.42-6.13-1.27-1.83-2.47-3.55-2.22-5.17.26-1.67,2.1-3.49,3.89-5.26,1.95-1.94,3.97-3.94,4.26-6.05q1.2-8.82,2.13-17.7,1.53-14.445,2.35-28.98Q960.765,570.21,960.56,550.17Z"
        transform="translate(-179 -28.60001)"
        fill="#636198"
      />
      <path
        d="M775.46243,691.92967a19.95192,19.95192,0,0,1,28.05046-12.21344L854.22744,630.166l6.98727,36.17616-49.23057,41.50763a20.06012,20.06012,0,0,1-36.5216-15.92Z"
        transform="translate(-179 -28.60001)"
        fill="#ffb6b6"
      />
      <path
        d="M832.16255,694.57779l100.10855-80.2856-.06092-.54329c-.11587-1.03909-11.69857-104.22213-17.65411-125.65241a58.89045,58.89045,0,0,0-22.1157-32.32848l-.27916-.18034-.33206.02433a29.885,29.885,0,0,0-27.2711,25.56121,211.68288,211.68288,0,0,0,9.17141,96.67142l5.99711,17.67087-64.13769,68.441Z"
        transform="translate(-179 -28.60001)"
        fill="#636198"
      />
      <circle cx="140.51414" cy="335.7904" r="43.3884" fill="#a0616a" />
      <path
        d="M261.221,694.86411l-.23665-.332c-12.6391-17.695,6.37013-60.21567,9.23717-66.37962h0a15.47493,15.47493,0,0,1-10.59239-16.52731l1.23594-10.506,1.28828-10.305-9.22938-9.22939-.02594-.23016-5.31135-46.47436-7.72256-30.86779A67.76838,67.76838,0,0,1,259.099,438.337l17.85061-2.83449L298.6437,414.6h34.98414l11.99529,11.46054c23.58323,19.33122,29.18174,10.93347,55.77462,47.32374l-19.04056,50.1646L393,597.537l.00648,13.38991,5.54321,6.16047a19.17013,19.17013,0,0,1,4.54738,16.58236l-.729,3.64472h0a19.17015,19.17015,0,0,1,11.19336,13.60323l1.94692,9.08479c1.66564,2.19793,12.65985,16.85732,12.65985,20.86483,0,.60168-.44412,1.18325-1.39721,1.82845-7.49243,5.07341-48.9044,15.41668-67.23286,8.08244-19.52337-7.80624-97.13192,3.90442-97.91384,4.02372Z"
        transform="translate(-179 -28.60001)"
        fill="#3f3d56"
      />
      <path
        d="M239.17843,518.85917l36.07646-7.06221c13.34185,19.92478,20.50455,74.407,20.50455,74.407l39.88641,82.71933a16.23049,16.23049,0,1,1-24.63644,13.04905l-52.0222-98.00728Z"
        transform="translate(-179 -28.60001)"
        fill="#a0616a"
      />
      <path
        d="M238.9764,527.41857l-1.02569-53.62007,16.97738-22.71273a29.84506,29.84506,0,0,1,29.37912-11.37089h0A29.77462,29.77462,0,0,1,308.12468,472.659c-2.8816,22.70477-9.42386,51.11833-24.225,57.54677l-.15.06515,15.3401,53.64885-56.888,7.94672Z"
        transform="translate(-179 -28.60001)"
        fill="#3f3d56"
      />
      <path
        d="M453.23916,522.68061s-43.29453-33.841-66.77565-38.70211l-20.783,30.32274,56.20678,34.44539,109.43114,31.641a16.23075,16.23075,0,1,0,8.34717-26.66056Z"
        transform="translate(-179 -28.60001)"
        fill="#a0616a"
      />
      <path
        d="M377.0692,529.48106l-45.11928-28.98992-9.42139-26.74579a29.845,29.845,0,0,1,6.90373-30.73708h0a29.77462,29.77462,0,0,1,40.62606-1.453c17.25975,15.03034,39.50262,20.06783,36.606,35.94256l8.30961,1.21351,42.49336,32.27207-25.05858,51.6862Z"
        transform="translate(-179 -28.60001)"
        fill="#3f3d56"
      />
      <path
        d="M270.29971,335.88131c4.872-7.53734,14.01471-12.56075,22.87786-11.14917a31.24175,31.24175,0,0,1,52.34689-18.22247c2.60961-2.07637,6.40288-2.18554,9.50044-.95079a20.03159,20.03159,0,0,1,7.71845,6.19546,44.95726,44.95726,0,0,1,6.29079,45.73608c1.08321-3.93781-2.86315-7.64241-6.85051-8.52606-3.987-.88365-8.16949-.04767-12.21289-.6231-5.18231-.7374-9.84078-3.74373-15.03129-4.4203-4.36221-.5686-8.749.5498-13.00292,1.67094-4.25389,1.121-8.62943,2.26414-12.99779,1.74543-4.36871-.51871-9.99591,16.03692-9.92039,27.69762.01435,2.20981-.4408,5.047-2.60038,5.51581-2.6595.57748-4.20059-3.09312-6.73194-4.09329-2.25526-.89117-4.95883.7603-5.74407,3.05451a7.63511,7.63511,0,0,0,1.45771,6.83718,22.56194,22.56194,0,0,0,5.47,4.7451l-1.04254.86707c-1.73415,2.291-5.33129,2.39023-7.83086.97284-2.49924-1.4174-4.14182-3.93918-5.62243-6.40185-4.6243-7.69145-8.68068-15.88658-10.31267-24.71163S265.428,343.41865,270.29971,335.88131Z"
        transform="translate(-179 -28.60001)"
        fill="#2f2e41"
      />
      <path
        d="M651.33519,408.79678a220.4619,220.4619,0,0,1,6.04185,56.0137,223.70013,223.70013,0,0,1-8.20689,55.7498A217.79818,217.79818,0,0,1,638.363,550.57883c-.77964,1.74818,1.80467,3.276,2.59042,1.51416a223.54472,223.54472,0,0,0,16.45275-54.93626,227.007,227.007,0,0,0,2.07918-57.33521,222.954,222.954,0,0,0-5.25736-31.82226c-.45194-1.87609-3.34553-1.08169-2.89283.79752Z"
        transform="translate(-179 -28.60001)"
        opacity="0.2"
      />
      <path
        d="M931.42779,537.10231,906.35,598.31992c-4.03607,9.85249-7.95237,19.76475-12.15259,29.5486-3.78178,8.80918-8.09453,17.65655-14.46024,24.91891-6.42508,7.33009-15.06751,13.28047-24.94117,14.44335-1.89429.2231-1.9168,3.22575,0,3,9.01927-1.06226,17.10284-5.54588,23.63112-11.74652,7.0596-6.70531,12.03466-15.246,16.09636-24.01159,4.52686-9.76947,8.37622-19.871,12.4567-29.83184l12.82174-31.29923,14.51874-35.44177c.73135-1.78532-2.1697-2.56278-2.89284-.79752Z"
        transform="translate(-179 -28.60001)"
        opacity="0.2"
      />
      <path
        d="M634.15988,592.66766a6.4162,6.4162,0,0,0,6.1831,4.331l26.49183-.68177a6.3285,6.3285,0,0,0,5.98085-4.657l3.74888-40.08721a9.79408,9.79408,0,0,0,4.23793.87078,9.66133,9.66133,0,1,0-.4849-19.31658,8.18393,8.18393,0,0,0-1.73618.18057,6.54753,6.54753,0,0,0-4.87405-2.103l-40.75051,1.05229a5.852,5.852,0,0,0-1.00519.1248,6.36454,6.36454,0,0,0-4.87318,8.26463Zm43.17346-43.81684,2.66763-9.55274a6.50645,6.50645,0,0,0,.02036-3.37457c.12944-.00035.24363-.06169.37307-.062a6.91049,6.91049,0,0,1,.32357,13.81714A6.50522,6.50522,0,0,1,677.33334,548.85082Z"
        transform="translate(-179 -28.60001)"
        fill="#2f2e41"
      />
      <path
        d="M631.95164,532.38152c.86818,4.96411,10.62909,8.45387,22.36585,7.84729,10.79271-.49726,19.61746-4.287,21.23421-8.77851a6.02542,6.02542,0,0,0-1.84436-.24587l-40.75051,1.05229A5.852,5.852,0,0,0,631.95164,532.38152Z"
        transform="translate(-179 -28.60001)"
        fill="#3f3d56"
      />
      <path
        d="M545.92154,538.64615a6.36454,6.36454,0,0,0-4.87318-8.26463,5.852,5.852,0,0,0-1.00519-.1248l-40.75051-1.05229a6.54753,6.54753,0,0,0-4.87405,2.103,8.18393,8.18393,0,0,0-1.73618-.18057,9.66133,9.66133,0,0,0-.4849,19.31658,9.79408,9.79408,0,0,0,4.23793-.87078l3.74888,40.08721a6.3285,6.3285,0,0,0,5.98085,4.657l26.49183.68177a6.4162,6.4162,0,0,0,6.1831-4.331ZM492.282,547.67862a6.91049,6.91049,0,0,1,.32357-13.81714c.12944.00034.24363.06168.37307.062a6.50645,6.50645,0,0,0,.02036,3.37457l2.66763,9.55274A6.50522,6.50522,0,0,1,492.282,547.67862Z"
        transform="translate(-179 -28.60001)"
        fill="#2f2e41"
      />
      <path
        d="M541.04836,530.38152c-.86818,4.96411-10.62909,8.45387-22.36585,7.84729-10.79271-.49726-19.61746-4.287-21.23421-8.77851a6.02542,6.02542,0,0,1,1.84436-.24587l40.75051,1.05229A5.852,5.852,0,0,1,541.04836,530.38152Z"
        transform="translate(-179 -28.60001)"
        fill="#3f3d56"
      />
      <path
        d="M502.42878,443.30109a17.29438,17.29438,0,0,1,25.74152,6.37423l61.01373-7.38113-14.25277,28.58036-55.75458,2.62766a17.38815,17.38815,0,0,1-16.74786-30.201Z"
        transform="translate(-179 -28.60001)"
        fill="#9e616a"
      />
      <path
        d="M701.41,385.09c-1.8,3.53-3.99,7.64-6.45,12.12-8.64,15.79-20.51,36.17-29.65,51.68-7.81,13.23-13.64,22.93-13.8,23.2l-.25.4-53.69,1.21-11.52.26-3.92.09-42.07.95.4-2.5,3.93-24.78.4-2.53,36.85-6.12,13.47-2.23,29.88-4.96,5.19-15.32a183.45653,183.45653,0,0,1,44.62-71.37c.59-.57,1.19-1.11,1.82-1.62.55-.44,1.11-.86,1.68-1.24,2.42,3.43,4.58,6.53,6.41,9.02l4.43,6.03A70.56615,70.56615,0,0,1,701.41,385.09Z"
        transform="translate(-179 -28.60001)"
        fill="#636198"
      />
      <path
        d="M478.15988,476.66766a6.4162,6.4162,0,0,0,6.1831,4.331l26.49183-.68177a6.3285,6.3285,0,0,0,5.98085-4.657l3.74888-40.08721a9.79408,9.79408,0,0,0,4.23793.87078,9.66133,9.66133,0,0,0-.4849-19.31658,8.18393,8.18393,0,0,0-1.73618.18057,6.54753,6.54753,0,0,0-4.87405-2.103l-40.75051,1.05229a5.852,5.852,0,0,0-1.00519.1248,6.36454,6.36454,0,0,0-4.87318,8.26463Zm43.17346-43.81684,2.66763-9.55274a6.50645,6.50645,0,0,0,.02036-3.37457c.12944-.00035.24363-.06169.37307-.062a6.91049,6.91049,0,0,1,.32357,13.81714A6.50522,6.50522,0,0,1,521.33334,432.85082Z"
        transform="translate(-179 -28.60001)"
        fill="#ccc"
      />
      <path
        d="M475.95164,416.38152c.86818,4.96411,10.62909,8.45387,22.36585,7.84729,10.79271-.49726,19.61746-4.287,21.23421-8.77851a6.02542,6.02542,0,0,0-1.84436-.24587l-40.75051,1.05229A5.852,5.852,0,0,0,475.95164,416.38152Z"
        transform="translate(-179 -28.60001)"
        fill="#3f3d56"
      />
    </svg>
  );
}

import Link from "next/link";
import HeartIcon from "./assets/heart-icon";
import Section from "./common/section";

export default function Mission() {
  return (
    <Section bg="bg-warning" className="text-center px-4">
      <h2 className="text-5xl font-semibold text-center uppercase mb-4 text-primary">
        Our Mission
      </h2>
      <p className="text-center mb-3 text-primary text-2xl md:text-3xl ">
        is to raise funds to support breast cancer research, clinical trials,
        education, and patient care.
      </p>
      <Link href="/donate">
        <a className="btn bg-success rounded-full text-white">
          <HeartIcon fill="#fff" className="mr-2 inline-block w-4" />
          <span className="inline-block align-middle">Donate</span>
        </a>
      </Link>
    </Section>
  );
}

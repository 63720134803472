import Image from "next/image";
import { ReactNode } from "react";

// interface UpdateCardProps {
//   title?: string;
//   image?: string;
//   alt?: string;
//   imgHeight?: number;
//   body?: any;
//   button?: string;
//   link?: string;
//   isFeature?: number;
//   order?: number;
//   special?: boolean;
//   jsx?: ReactNode;
// }

interface UpdateCardProps {
  data: {
    title: string;
    image: string;
    alt: string;
    imgHeight: number;
    button: string;
    link: string;
    isFeature: number;
    special: boolean;
    imgBackground?: string;
  };
  content: string;
}

export default function UpdateCard({
  data: {
    title,
    image,
    alt,
    imgHeight,
    button,
    link,
    isFeature,
    special,
    imgBackground = "",
  },
  content,
}: UpdateCardProps) {
  return (
    <div
      className={`flex flex-col w-full p-8 space-y-8 text-center border rounded-lg shadow-md ${
        isFeature
          ? "bg-primary border-primary md:col-span-2 lg:col-span-1"
          : "bg-white border-white"
      }`}
    >
      <div className="flex-1">
        <p
          className={`font-medium text-lg ${
            isFeature ? "text-white" : "text-primary"
          } uppercase border-b border-success pb-2 mb-4`}
        >
          {title}
        </p>

        {/* <h2 className="text-5xl font-bold text-gray-800 uppercase dark:text-gray-100">
        $0
      </h2> */}

        {image && (
          <div className={imgBackground}>
            <p
              className={`relative w-full mb-2`}
              style={{ height: imgHeight ? imgHeight : 50 }}
            >
              <Image
                alt={alt || "Update"}
                src={image}
                layout="fill"
                objectFit="contain"
              />
            </p>
          </div>
        )}

        {!special && content && (
          <div
            className={`font-medium mt-4 ${
              isFeature ? "text-gray-200" : "text-gray-500"
            } whitespace-pre-wrap`}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
        {special && <div dangerouslySetInnerHTML={{ __html: content }} />}
        {/* {special && (
          <div
            className={`grid grid-cols-4 gap-1 mt-8 {
            isFeature ? "text-white" : "text-primary"
          }`}
          >
            <div className="col-span-1 font-semibold uppercase mb-4 justify-self-start">
              When:
            </div>
            <div className="col-span-3 mb-4 justify-self-start text-left ml-4">
              Any day in <span className="font-bold">September</span> but no
              later than <span className="font-bold">Sept. 25, 2022</span>.
            </div>
            <div className="col-span-1 font-semibold uppercase mb-4 justify-self-start">
              Where:
            </div>
            <div className="col-span-3 mb-4 justify-self-start text-left ml-4">
              Any golf course or miniature golf course.
            </div>
            <div className="col-span-1 font-semibold uppercase mb-4 justify-self-start">
              Fee:
            </div>
            <div className="col-span-3 mb-4 justify-self-start text-left ml-4">
              Adults $25, Studends/Children $10
            </div>
            <div className="col-span-1 font-semibold uppercase mb-4 justify-self-start">
              Cost:
            </div>
            <div className="col-span-3 mb-4 justify-self-start text-left ml-4">
              Players are responsible for their own course fees.
            </div>
            <div className="col-span-1 font-semibold uppercase mb-4 justify-self-start">
              Prizes:
            </div>
            <div className="col-span-3 mb-4 justify-self-start text-left ml-4">
              Low scoring individual and foursome for regular golf and low
              scoring male and female for miniature golf.
            </div>
            <div className="col-span-1 font-semibold uppercase mb-4 justify-self-start">
              Awards:
            </div>
            <div className="col-span-3 mb-4 justify-self-start text-left ml-4">
              Sept. 29, 2022 - Location: TBD.
            </div>
          </div>
        )} */}
      </div>
      {!!isFeature && !!button && !!link && (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="w-full px-4 py-2 mt-10 tracking-wide text-primary capitalize transition-colors duration-200 transform bg-white rounded-md hover:bg-neutral focus:outline-none focus:bg-neutral focus:ring focus:ring-gray-200 focus:ring-opacity-80"
        >
          {button}
        </a>
      )}
      {!!!isFeature && !!button && !!link && (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="w-full px-4 py-2 mt-10 tracking-wide text-white capitalize transition-colors duration-200 transform bg-primary rounded-md hover:bg-secondary focus:outline-none focus:bg-secondary focus:ring focus:ring-secondary focus:ring-opacity-80"
        >
          {button}
        </a>
      )}
    </div>
  );
}

import Section from "./common/section";
import QuoteLeft from "./assets/quote-left";
import QuoteRight from "./assets/quote-right";

export default function Quote() {
  return (
    <Section
      bg="bg-warning px-4"
      containerClassName="flex flex-col text-primary-focus"
    >
      <QuoteLeft className="w-20 h-20 fill-success mb-4" />
      <div className="flex w-full items-center text-3xl">
        Breast cancer is the most commonly occurring cancer, and the second most
        common cause of cancer deaths in women in the United States.
      </div>
      <div>(National Cancer Institute, 2017)</div>
      <QuoteRight className="w-20 h-20 fill-success self-end -mt-4" />
    </Section>
  );
}

import Image from "next/image";
import Section from "./common/section";
import SectionTitle from "./common/section-title";

export default function Affiliations() {
  return (
    <Section className="px-4">
      <SectionTitle>Affiliations</SectionTitle>
      <div className="flex justify-evenly mt-8">
        <div className="relative w-[250px] h-[100px] cursor-pointer">
          <a
            href="https://www.ahn.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              alt="AHN"
              src="/affiliations/AHN_CMYK.jpg"
              objectFit="contain"
              layout="fill"
            />
          </a>
        </div>
        <div className="relative w-[250px] h-[100px] cursor-pointer">
          <a
            href="http://www.nsabp.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              alt="NSABP"
              src="/affiliations/nsabp.png"
              objectFit="contain"
              layout="fill"
            />
          </a>
        </div>
        <div className="relative w-[250px] h-[100px]">
          <Image
            alt="BPP"
            src="/affiliations/bpplogo.jpg"
            objectFit="contain"
            layout="fill"
          />
        </div>
        <div className="relative w-[100px] h-[100px] cursor-pointer">
          <a
            href="https://www.face2facehealing.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              alt="Face2Face"
              src="/affiliations/face2face.png"
              objectFit="contain"
              layout="fill"
            />
          </a>
        </div>
      </div>
      <div className="flex justify-evenly mt-8">
        <div className="relative w-[400px] h-[100px] cursor-pointer">
          <Image
            alt="KJ"
            src="/affiliations/kjlogo.jpg"
            objectFit="contain"
            layout="fill"
          />
        </div>
        <div className="relative w-[400px] h-[100px] cursor-pointer">
          <a
            href="https://www.breastcancertrials.org/BCTIncludes/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              alt="BreastCancerTrialsOrg"
              src="/affiliations/breastcancertrialsorg.jpg"
              objectFit="contain"
              layout="fill"
            />
          </a>
        </div>
      </div>
    </Section>
  );
}

import Section from "./common/section";
import SectionTitle from "./common/section-title";

export default function FeatureTrial() {
  return (
    <Section id="feature-trial" bg="bg-primary" className="text-center px-4">
      <SectionTitle color="text-white">Feature Trial</SectionTitle>
      <div className="bg-white mt-8 shadow-lg rounded">
        <div className="py-8 prose px-4 md:mx-auto text-left">
          <h2>
            BRCA-P: A Randomized, Double-Blind, Placebo-Controlled,
            Multi-Center, International Phase 3 Study to Determine the
            Preventive Effect of Denosumab on Breast Cancer in Women Carrying a
            BRCA1 Germline Mutation
          </h2>
          <h4>Breaking it down:</h4>
          <p>
            Is there a non-surgical way to reduce breast cancer risk in women
            with a BRCA1 mutation?
          </p>
          <h4>Eligibility Criteria:</h4>
          <ol>
            <li>Women who have a confirmed BRCA1 gene mutation (variant)</li>
            <li>25 to 55 years of age</li>
            <li>Do NOT have a history of breast or ovarian cancer</li>
            <li>Not pregnant or breastfeeding</li>
            <li>Have not had a mastectomy</li>
          </ol>
          <h4>Treatments:</h4>
          <ul>
            <li>Subcutaneous (SQ) injection under the skin of denosumab or</li>
            <li>Placebo once every 6 months for 5 years</li>
          </ul>
          <h4>Drugs used:</h4>
          <ul>
            <li>
              Denosumab is a well-tolerated drug approved by the FDA to treat
              osteoporosis in healthy people and in cancer patients whose cancer
              has spread to their bones. Side effects include muscle and bone
              pain, shortness of breath, and low levels of calcium and phosphate
              in the blood.
            </li>
          </ul>
          <h4>Locations and Contacts:</h4>
          <p>30 locations through-out the United States, include Pittsburgh.</p>
          <ul>
            <li>
              <div>Carol Glock M.Ed</div>
              <div>Phone: 412-680-6496</div>
            </li>
            <li>
              <div>Cortney Montgomery BSN, RN, CCRP, CBCN</div>
              <div>Phone: 412-641-1840</div>
            </li>
          </ul>
        </div>
      </div>
    </Section>
  );
}

import { ReactNode } from "react";

export default function SectionTitle({
  color,
  children,
}: {
  color?: string;
  children: ReactNode;
}) {
  return (
    <>
      <h2
        className={`text-2xl lg:text-4xl text-left uppercase mb-4 ${
          color || "text-primary"
        }`}
      >
        {children}
      </h2>
      <div className=" border-2 border-success w-10"></div>
    </>
  );
}

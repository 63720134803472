export default function Contact() {
  return (
    <div
      className="hero min-h-[350px]"
      style={{
        backgroundImage: "url(/contact/contact-background.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <div
        className="hero-overlay bg-opacity-50"
        style={{ backgroundColor: "rgba(155, 155, 155, 0.5)" }}
      ></div>
      <div className="flex w-full container justify-center lg:justify-end">
        <div className="w-full lg:w-1/2 prose">
          <p className="w-full text-black lg:w-1/2 mx-auto text-xl text-center">
            If you want to discuss clinical trials or schedule a community
            outreach presentation please contact us at:
          </p>
          <p className="w-full px-8 lg:w-1/2 mx-auto text-xl font-bold text-center">
            <span className="block mb-4 text-center">
              Phone: (412) 680-6496
            </span>
            <a
              href="mailto:cglock@glockfoundation.org"
              target="_blank"
              rel="noopener noreferrer"
              className="btn bg-success text-white border-success w-full rounded-full"
            >
              Contact us
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

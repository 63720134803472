import Image from "next/image";
import Section from "./common/section";
import Community from "../public/community.png";
import MedicalTeam from "../public/medical-team.png";
import Webinar from "../public/webinar.png";
import Medicine from "./assets/contributions/medicine";
import LivesMatter from "./assets/contributions/lives-matter";
import Conversation from "./assets/contributions/conversation";

const details = [
  {
    Icon: LivesMatter,
    description:
      "Assists breast cancer clinical trial patients with the added cost for transportation, meals and child care.",
  },
  {
    Icon: Medicine,
    description:
      "Provides educational opportunities for breast cancer physicians to attend a symposium about current clinical trials.",
  },
  {
    Icon: Conversation,
    description:
      "Educates the general public on breast cancer prevention, detection, treatment and clinical trials.",
  },
];

export default function Contribution() {
  return (
    <Section bg="bg-warning">
      <h3 className="text-center text-2xl font-bold mb-4 uppercase text-primary">
        Your Contribution...
      </h3>
      <div className="flex flex-wrap md:flex-nowrap">
        {details.map(({ description, Icon }, index) => (
          <div key={`details-${index}`} className="flex flex-col items-center">
            <div
              className="flex mask mask-hexagon-2 shadow-lg justify-center items-center bg-success"
              style={{
                width: "250px",
                height: "250px",
              }}
            >
              <Icon />
              {/* <Image
                className=""
                width={100}
                height={100}
                src={detail.icon}
                alt="Medical Team"
              /> */}
            </div>
            <div className="flex flex-col items-center justify-center px-12 pt-6 pb-12 text-xl text-primary text-center">
              {description}
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
}

import Section from "./common/section";
import SectionTitle from "./common/section-title";

export default function Feature() {
  return (
    <Section bg="bg-white" className="text-center px-4">
      <SectionTitle>Feature</SectionTitle>
      <div className="mt-8">
        <video id="feature-video" width="100%" height="100%" controls loop>
          <source src="/press/commercial.mp4" type="video/mp4" />
        </video>
      </div>
    </Section>
  );
}

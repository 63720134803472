import ChevronRight from "./assets/chevron-right";
import Section from "./common/section";
import SectionTitle from "./common/section-title";
import UpdateCard from "./common/update-card";

export default function Updates({ updates }: { updates: any }) {
  const featureFirstUpdates = [...updates].sort(
    (a, b) => b.data.isFeature - a.data.isFeature
  );

  return (
    <Section id="updates" className="bg-warning px-4">
      <SectionTitle>Updates</SectionTitle>

      <p className="max-w-2xl mx-auto mt-4 text-center text-gray-500 xl:mt-6 dark:text-gray-300">
        Allegheny Health Network currently has <strong>13</strong> Active Breast
        Cancer Clinicals Trials
      </p>

      <p className="text-center mt-4">
        <a
          className="btn gap-2 bg-success text-white hover:bg-secondary"
          style={{ borderRadius: 40 }}
          href="https://www.ahn.org/health-care-professionals/research/active-clinical-trials.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>More Details</span> <ChevronRight className="h-6 w-6" />
        </a>
      </p>

      <div className="grid grid-cols-1 gap-8 mt-6 lg:hidden">
        {featureFirstUpdates.map((update: any, index: any) => (
          <div key={`update-${update.data.title}`} className="col-span-1">
            <UpdateCard key={`update-card-sm-${index}`} {...update} />
          </div>
        ))}
      </div>

      <div className="hidden mt-6 gap-8 lg:grid lg:grid-cols-3 xl:mt-12 xl:gap-12">
        {updates.map((update: any, index: any) => (
          <UpdateCard key={`update-card-lg-${index}`} {...update} />
        ))}
      </div>
    </Section>
  );
}

import ShowingSupport from "./assets/contributions/showing-support";
import Section from "./common/section";
import SectionTitle from "./common/section-title";

export default function Outreach() {
  return (
    <Section id="programs" bg="bg-primary px-4">
      <SectionTitle color="text-white">
        Community Outreach Programs
      </SectionTitle>
      <div className="flex flex-col md:flex-row w-full items-center">
        <div className="flex w-4/5 md:w-2/5 md:mr-10">
          <ShowingSupport />
        </div>
        <div className="md:ml-10 w-4/5">
          <ul className="list-disc font-bold text-white text-xl">
            <li className="my-6">
              Learn 5 Strategies for Prevention and Treatment of Breast Cancer
            </li>
            <li className="my-6">
              Why Me, Cancer is Only One Chapter of a Person’s Life
            </li>
            <li className="my-6">
              Benefits of Participating in a Breast Cancer Clinical Trial
            </li>
          </ul>
        </div>
      </div>
    </Section>
  );
}

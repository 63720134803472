import Image from "next/image";
import Logo from "../public/glockfoundationlogo2.png";

const Hero = () => {
  return (
    <div
      className="hero min-h-[375px] md:min-h-[600px] lg:min-h-screen md:pt-0"
      style={{
        backgroundImage: "url(/header-background.jpeg)",
        backgroundPosition: "80% 90%",
        backgroundSize: "140% auto",
      }}
    >
      <div
        className="hero-overlay bg-opacity-50"
        style={{ backgroundColor: "rgba(155, 155, 155, 0.4)" }}
      ></div>
      <div className="hero-content text-center text-neutral-content relative w-full h-full p-0 max-w-full pt-16">
        <div className="w-4/5 relative h-full">
          <Image src={Logo} alt="Logo" layout="fill" objectFit="contain" />
        </div>
      </div>
    </div>
  );
};

export default Hero;

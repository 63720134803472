import Section from "./common/section";
// import HandsHeart from ''

export default function Approach() {
  return (
    <div
      className="hero min-h-[400px]"
      style={{
        backgroundImage: "url(/hands-heart-2.jpg)",
      }}
    >
      <div
        className="hero-overlay bg-opacity-80"
        style={{ backgroundColor: "rgba(82, 70, 86, 0.8)" }}
      ></div>
      <div className="hero-content text-center">
        <div className="max-w-lg p-12">
          <h3 className="mb-5 text-3xl font-bold uppercase text-white">
            Our Approach is Simple:
          </h3>
          <p className="mb-5 uppercase text-4xl font-thin text-white">
            it starts with you.
          </p>
        </div>
      </div>
    </div>
  );
}

import { ReactNode } from "react";

export default function Section({
  id,
  children,
  bg,
  className,
  containerClassName,
}: {
  id?: string;
  children: ReactNode;
  bg?: string;
  className?: string;
  containerClassName?: string;
}) {
  return (
    <div
      id={id}
      className={`flex flex-col items-center justify-center py-14 min-h-[400px] ${
        bg || ""
      } ${className || ""}`}
    >
      <div className={`container ${containerClassName || ""}`}>{children}</div>
    </div>
  );
}
